// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contents-patient-testimonials-js": () => import("./../../src/pages/contents/patient-testimonials.js" /* webpackChunkName: "component---src-pages-contents-patient-testimonials-js" */),
  "component---src-pages-contents-resources-abi-ankle-brachial-index-test-js": () => import("./../../src/pages/contents/resources/abi-ankle-brachial-index-test.js" /* webpackChunkName: "component---src-pages-contents-resources-abi-ankle-brachial-index-test-js" */),
  "component---src-pages-contents-resources-hemorrhoids-js": () => import("./../../src/pages/contents/resources/hemorrhoids.js" /* webpackChunkName: "component---src-pages-contents-resources-hemorrhoids-js" */),
  "component---src-pages-contents-resources-high-blood-pressure-js": () => import("./../../src/pages/contents/resources/high-blood-pressure.js" /* webpackChunkName: "component---src-pages-contents-resources-high-blood-pressure-js" */),
  "component---src-pages-contents-resources-high-cholesterol-js": () => import("./../../src/pages/contents/resources/high-cholesterol.js" /* webpackChunkName: "component---src-pages-contents-resources-high-cholesterol-js" */),
  "component---src-pages-contents-resources-index-js": () => import("./../../src/pages/contents/resources/index.js" /* webpackChunkName: "component---src-pages-contents-resources-index-js" */),
  "component---src-pages-contents-resources-insurance-accepted-js": () => import("./../../src/pages/contents/resources/insurance-accepted.js" /* webpackChunkName: "component---src-pages-contents-resources-insurance-accepted-js" */),
  "component---src-pages-contents-resources-mammograms-js": () => import("./../../src/pages/contents/resources/mammograms.js" /* webpackChunkName: "component---src-pages-contents-resources-mammograms-js" */),
  "component---src-pages-contents-resources-patient-forms-js": () => import("./../../src/pages/contents/resources/patient-forms.js" /* webpackChunkName: "component---src-pages-contents-resources-patient-forms-js" */),
  "component---src-pages-contents-resources-tetanus-vaccination-js": () => import("./../../src/pages/contents/resources/tetanus-vaccination.js" /* webpackChunkName: "component---src-pages-contents-resources-tetanus-vaccination-js" */),
  "component---src-pages-contents-resources-vaccination-for-adults-js": () => import("./../../src/pages/contents/resources/vaccination-for-adults.js" /* webpackChunkName: "component---src-pages-contents-resources-vaccination-for-adults-js" */),
  "component---src-pages-contents-resources-why-do-i-need-a-colonoscopy-js": () => import("./../../src/pages/contents/resources/why-do-i-need-a-colonoscopy.js" /* webpackChunkName: "component---src-pages-contents-resources-why-do-i-need-a-colonoscopy-js" */),
  "component---src-pages-contents-specialties-js": () => import("./../../src/pages/contents/specialties.js" /* webpackChunkName: "component---src-pages-contents-specialties-js" */),
  "component---src-pages-contents-why-sunstate-js": () => import("./../../src/pages/contents/why-sunstate.js" /* webpackChunkName: "component---src-pages-contents-why-sunstate-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-provider-farideh-zadeh-md-js": () => import("./../../src/pages/provider/farideh-zadeh-md.js" /* webpackChunkName: "component---src-pages-provider-farideh-zadeh-md-js" */),
  "component---src-pages-provider-index-js": () => import("./../../src/pages/provider/index.js" /* webpackChunkName: "component---src-pages-provider-index-js" */),
  "component---src-pages-provider-pothen-koruth-md-js": () => import("./../../src/pages/provider/pothen-koruth-md.js" /* webpackChunkName: "component---src-pages-provider-pothen-koruth-md-js" */),
  "component---src-pages-provider-zia-fatemi-md-js": () => import("./../../src/pages/provider/zia-fatemi-md.js" /* webpackChunkName: "component---src-pages-provider-zia-fatemi-md-js" */),
  "component---src-pages-requestconfirmation-js": () => import("./../../src/pages/requestconfirmation.js" /* webpackChunkName: "component---src-pages-requestconfirmation-js" */),
  "component---src-pages-services-adhd-js": () => import("./../../src/pages/services/adhd.js" /* webpackChunkName: "component---src-pages-services-adhd-js" */),
  "component---src-pages-services-arthritis-js": () => import("./../../src/pages/services/arthritis.js" /* webpackChunkName: "component---src-pages-services-arthritis-js" */),
  "component---src-pages-services-asthma-js": () => import("./../../src/pages/services/asthma.js" /* webpackChunkName: "component---src-pages-services-asthma-js" */),
  "component---src-pages-services-back-pain-js": () => import("./../../src/pages/services/back-pain.js" /* webpackChunkName: "component---src-pages-services-back-pain-js" */),
  "component---src-pages-services-contraception-js": () => import("./../../src/pages/services/contraception.js" /* webpackChunkName: "component---src-pages-services-contraception-js" */),
  "component---src-pages-services-depression-anxiety-js": () => import("./../../src/pages/services/depression-anxiety.js" /* webpackChunkName: "component---src-pages-services-depression-anxiety-js" */),
  "component---src-pages-services-dermatology-js": () => import("./../../src/pages/services/dermatology.js" /* webpackChunkName: "component---src-pages-services-dermatology-js" */),
  "component---src-pages-services-diabetes-js": () => import("./../../src/pages/services/diabetes.js" /* webpackChunkName: "component---src-pages-services-diabetes-js" */),
  "component---src-pages-services-geriatric-medicine-js": () => import("./../../src/pages/services/geriatric-medicine.js" /* webpackChunkName: "component---src-pages-services-geriatric-medicine-js" */),
  "component---src-pages-services-heart-disease-js": () => import("./../../src/pages/services/heart-disease.js" /* webpackChunkName: "component---src-pages-services-heart-disease-js" */),
  "component---src-pages-services-high-cholesterol-js": () => import("./../../src/pages/services/high-cholesterol.js" /* webpackChunkName: "component---src-pages-services-high-cholesterol-js" */),
  "component---src-pages-services-hypertension-js": () => import("./../../src/pages/services/hypertension.js" /* webpackChunkName: "component---src-pages-services-hypertension-js" */),
  "component---src-pages-services-immunizations-js": () => import("./../../src/pages/services/immunizations.js" /* webpackChunkName: "component---src-pages-services-immunizations-js" */),
  "component---src-pages-services-index-js": () => import("./../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-preventative-care-js": () => import("./../../src/pages/services/preventative-care.js" /* webpackChunkName: "component---src-pages-services-preventative-care-js" */),
  "component---src-pages-services-primary-care-js": () => import("./../../src/pages/services/primary-care.js" /* webpackChunkName: "component---src-pages-services-primary-care-js" */),
  "component---src-pages-services-sleep-disorders-js": () => import("./../../src/pages/services/sleep-disorders.js" /* webpackChunkName: "component---src-pages-services-sleep-disorders-js" */),
  "component---src-pages-services-thyroid-issues-js": () => import("./../../src/pages/services/thyroid-issues.js" /* webpackChunkName: "component---src-pages-services-thyroid-issues-js" */),
  "component---src-pages-services-weight-loss-js": () => import("./../../src/pages/services/weight-loss.js" /* webpackChunkName: "component---src-pages-services-weight-loss-js" */),
  "component---src-pages-services-well-woman-exam-js": () => import("./../../src/pages/services/well-woman-exam.js" /* webpackChunkName: "component---src-pages-services-well-woman-exam-js" */),
  "component---src-pages-services-wellness-exams-js": () => import("./../../src/pages/services/wellness-exams.js" /* webpackChunkName: "component---src-pages-services-wellness-exams-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

